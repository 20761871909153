import React from 'react'
import { useFormikContext } from 'formik'
import { Typography, Box } from '@mui/material'
import Select from 'atoms/common/Select'
import Switch from 'atoms/common/Switch'
// Helpers
import {
    currencyOptions,
    reportingStatusOptions,
    reportTypeOptions,
    exportFrequencyOptions,
} from 'helpers/account/selectOptions'
// Types
import { AccountData } from 'types/account'
// Styles
import * as Styled from './AccountGeneralSettings.styled'

const GeneralSettings = () => {
    const { values, errors, touched, setFieldValue, handleChange } = useFormikContext<AccountData>()

    const onShowAdvancedFiltersChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue('showAdvancedFilters', event.target.checked)
    }

    return (
        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={4}>
            <Box gridColumn="span 12">
                <Typography variant="h6">General Setting</Typography>
            </Box>
            <Box gridColumn="span 3">
                <Select
                    prefixLabel="Reporting Status"
                    options={reportingStatusOptions}
                    defaultValue={values.reportingStatus}
                    onSelectChanged={handleChange}
                    name="reportingStatus"
                    error={errors.reportingStatus && touched.reportingStatus && errors.reportingStatus}
                />
            </Box>

            <Box gridColumn="span 9"></Box>

            <Box gridColumn="span 3">
                <Select
                    prefixLabel="Currency"
                    required
                    options={currencyOptions}
                    onSelectChanged={handleChange}
                    defaultValue={values.reportingCurrency}
                    name="reportingCurrency"
                    error={errors.reportingCurrency && touched.reportingCurrency && errors.reportingCurrency}
                />
            </Box>

            <Box gridColumn="span 3">
                <Select
                    prefixLabel="Report Type"
                    onSelectChanged={handleChange}
                    required
                    options={reportTypeOptions}
                    defaultValue={values.reportType}
                    name="reportType"
                    error={errors.reportType && touched.reportType && errors.reportType}
                />
            </Box>

            <Box gridColumn="span 3">
                <Select
                    prefixLabel="Export Frequency"
                    required
                    options={exportFrequencyOptions}
                    onSelectChanged={handleChange}
                    defaultValue={values.exportFrequency}
                    name="exportFrequency"
                    error={errors.exportFrequency && touched.exportFrequency && errors.exportFrequency}
                />
            </Box>

            <Box gridColumn="span 3">
                <Styled.Label>Filters Option *</Styled.Label>
                <Switch
                    name="showAdvancedFilters"
                    label="Advanced Filters"
                    defaultValue={values.showAdvancedFilters}
                    handleChange={onShowAdvancedFiltersChange}
                />
            </Box>

            <Box gridColumn="span 12">
                <hr />
            </Box>
        </Box>
    )
}

export default GeneralSettings
