export enum ReportStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}

export enum AccountType {
    PROPERTY = 'single',
    GROUP_ACCOUNT = 'group',
}

export enum Currency {
    EUR = 'EUR',
    USD = 'USD',
    GBP = 'GBP',
    AUD = 'AUD',
}

export enum FREQUENCY {
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
}

export interface AccountData {
    bid: number
    version: number
    updatedAt: string
    name: string
    type: string
    reportingStatus: string
    reportingCurrency: string
    reportType: string
    exportFrequency: string
    showAdvancedFilters: boolean
    tagGroups?: TagGroupData[]
    childRelations?: ChildRelation[]
}

export interface TagGroupsPayload {
    tagGroups: TagGroupData[]
}

export interface TagGroupData {
    id?: number
    version?: number
    updatedAt?: string
    bid?: number
    name: string
    tags: TagData[]
}

export interface TagData {
    id?: number
    version?: number
    updatedAt?: string
    tagGroupId?: number
    name: string
}

export interface ChildRelation {
    version: number
    updatedAt: string
    included: boolean
    tagIds?: (string | number)[]
    account: AccountChildRelation
}

export interface AccountChildRelation {
    bid: number
    name: string
    reportingStatus: string
}
