import { ChangeEvent, ReactElement } from 'react'

export interface SelectProps {
    prefixLabel?: string
    label?: string
    required?: boolean
    name?: string
    defaultValue?: string
    disabled?: boolean
    options: (SelectOption | ReactNode)[]
    onSelectChanged: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
    error?: string
}

export enum SelectOptionType {
    TEXT = 'text',
    COMPONENT = 'component',
    DIVIDER = 'divider',
}

export interface SelectOption {
    value: string
    component?: ReactElement | string
    type?: SelectOptionType
    name?: string
    disabled?: boolean
}

export interface SelectLabelProps {
    hasError: boolean
}
