import React, { FC, useEffect, useState } from 'react'
import { FormControlLabel, Switch as MuiSwitch } from '@mui/material'
import { SwitchProps } from './Switch.d'

const Switch: FC<SwitchProps> = ({ defaultValue = false, id, label, name, disabled, required, handleChange }) => {
    const [checked, setChecked] = useState<boolean>(defaultValue)

    useEffect(() => {
        setChecked(defaultValue)
    }, [defaultValue])

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked)
        handleChange?.(event)
    }

    return (
        <FormControlLabel
            control={
                <MuiSwitch
                    name={name}
                    onChange={onChange}
                    inputProps={{ id }}
                    checked={checked}
                    disabled={disabled}
                    required={required}
                    color="primary"
                />
            }
            label={label}
        />
    )
}

export default Switch
